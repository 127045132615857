import React, { useEffect, useState, useRef, useCallback } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  CREATE_LOG,
  GENERATE_RTC_TOKEN,
  OTHER_USER_DETAIL,
  IMAGEURL,
  REJECT_CALL,
  SEND_MESSAGE
  , FRIEND_CALL_END
} from "../../config";
import { handleRequest } from "../../services";
import { IMAGES } from "../../components/images";
import { useSidebarContext } from "../../context/sidebarContext";
import io from "socket.io-client";
const APP_ID = import.meta.env.VITE_AGORA_APP_ID
import { SOCKETURL } from "../../config";
import Lottie from "lottie-react";
import loader_gif from "../../assets/gif/loader_gif.json"
import { toast } from 'react-toastify';
import Spinner from "../../components/loader/loader";
import VideoPlayer from "../home/components/VideoPlayer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import ConfirmModal from "../modals/confirmModal";
const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});
const socket = io(SOCKETURL);

const VideoCall = (props) => {
  const { user_id, setOtherUser, setShowChat, setVideoCallStart, setIsInterestModalOpen, themVideo, setThemVideo, toastId, handleShowChatWindow, setIsSidebarVisible, showChat } = props;
  const { setChannelForChat, setMessages,
    setCallStartTime,
    setCallEndTime, setCallStartOpen,
    userDetail, receiveCall, setReceiveCall,
    setEndedChatModalFriend } = useSidebarContext();
  const [controlsVisible, setControlsVisible] = useState(true);
  const [room, setRoom] = useState();
  const [myVideo, setMyVideo] = useState();
  const [themAudio, setThemAudio] = useState();
  const channelRef = useRef();
  const rtcClientRef = useRef();
  const userJoined = useRef(false)
  const [localTracks, setLocalTracks] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [muteCall, setMuteCall] = useState(false);
  const [callStart, setCallStart] = useState(false);
  const [muteVideoCall, setMuteVideoCall] = useState(false);
  const [friendCancel, setFriendCancel] = useState(false);
  const interest_matching = localStorage.getItem("interest_matching")
  const friend_Call = JSON.parse(localStorage.getItem("activeChatUser"))
  const other_response = receiveCall && JSON.parse(localStorage.getItem("otherResponse"))
  const navigate = useNavigate()
  const isChatting = room;
  const pathname = localStorage.getItem("lastPathname");
  const friendData = localStorage.getItem("friend");
  let friend_user = null;
  if (friendData) {
    try {
      friend_user = JSON.parse(friendData);
    } catch (error) {
      console.error("Error parsing JSON: ", error);
    }
  }

  const callOption = localStorage.getItem("callOption")
  const callEnded = useRef(false);
  const [confrimation, setConfirmation] = useState(false)

  useEffect(() => {
    socket.on(`callReject${user_id}`, (response) => {
      if (response) {
        toast(`Call cancelled`, {
          style: { color: 'white' }
        });
      }
      handleLeaveCall("end")
      setFriendCancel(false)
    });
    return () => {
      socket.off(`callReject${user_id}`);
    }
  }, [friendCancel])

  const getOtherUserDetail = async (id) => {
    try {
      const resposne = await handleRequest("GET", `${OTHER_USER_DETAIL}?user_id=${id}`)
      localStorage.setItem("otherUserId", resposne.body.id)
      setOtherUser(resposne?.body);
    } catch (error) {
      console.log(error)
    }
  }

  const getCancelCall = async () => {
    const tokenCheck = localStorage.getItem("tokenCheck")

    if (tokenCheck == 1) {
      handleLeaveCall("end")
      localStorage.setItem("tokenCheck", 0)

    }
    else {
      try {
        const resposne = await handleRequest("GET", `${REJECT_CALL}?userId=${receiveCall ? other_response.user_id?.friend_id : friend_Call?.friend_id}`)
        if (resposne) {
          setFriendCancel(true)
          handleLeaveCall("end")
        }
      } catch (error) {
        console.log(error)
      }
    }
  }



  const createChannel = async (id) => {
    try {
      const response = await handleRequest(
        "GET",
        `${GENERATE_RTC_TOKEN}?user_id=${id}&calling=1&calling_type=${callOption == 1 ? 0 : 1}`
      );
      if (response?.code == 200) {
        setRoom(response.body.channel);
        setChannelForChat(response.body.channel);
        return response;
      }
      else if (response.code == 400) {
        toast(`${friend_Call.username} is busy on another call.`, {
          style: { color: 'white' }
        });
        localStorage.setItem("tokenCheck", 1)
        setTimeout(() => {
          handleLeaveCall("end")

        }, 2000)

      }
    } catch (error) {
      console.log(error)
    }
  };


  const playConnectionSound = () => {
    const audio = new Audio(IMAGES.AUDIO);
    return new Promise((resolve) => {
      audio.play();
      audio.onended = () => resolve();
    });
  };

  const connectToAgoraRtc = async (
    channel,
    token,
    onVideoConnect,
    onWebcamStart,
    onAudioConnect,
  ) => {
    if (client.connectionState === "CONNECTING" || client.connectionState === "CONNECTED") {
      return;
    }
    try {
      await client.join(APP_ID, channel, token, null);
      console.log("Joined channel:", channel);

      client.on("user-published", async (themUser, mediaType) => {
        setCallStart(false);
        try {
          await client.subscribe(themUser, mediaType);
          console.log("Subscribed to user:", themUser.uid, "MediaType:", mediaType);

          if (mediaType === "video") {
            const videoTrack = themUser.videoTrack;
            onVideoConnect(videoTrack);
            console.log("Video track connected:", videoTrack);
          }

          if (mediaType === "audio") {
            const audioTrack = themUser.audioTrack;
            await playConnectionSound();
            onAudioConnect(audioTrack);
            audioTrack?.play();
            console.log("Audio track connected and playing:", audioTrack);
          }

          localStorage.setItem("otherFriendAccountType", 1);
        } catch (error) {
          console.error("Error subscribing to user:", error);
        }
      });

      client.on("user-joined", async (user) => {
        console.log("User joined:", user.uid);
        setVideoCallStart(true);
        setLoading(false);
        setCallStartOpen(true);

        if (!userJoined.current) {
          setCallStartTime(new Date());
          localStorage.setItem("startTime", new Date());


        }
      });

      const [audioTrack, videoTrack] = await Promise.all([
        AgoraRTC.createMicrophoneAudioTrack(),
        AgoraRTC.createCameraVideoTrack()
      ]);

      setLocalTracks([audioTrack, videoTrack]);
      onWebcamStart(videoTrack);
      await client.publish([audioTrack, videoTrack]);
      console.log("Published local tracks:", audioTrack, videoTrack);

      if (callOption == 1 || other_response?.calling_type == 0) {
        videoTrack?.setEnabled(false);
        setMuteVideoCall(!muteVideoCall);
      }

      return { tracks: [audioTrack, videoTrack], client };
    } catch (error) {
      setLoading(false);
      console.error("Error connecting to Agora RTC:", error);
    }
  };

  const connectToUser = async () => {
    setThemAudio(undefined);
    setThemVideo(undefined);
    setMyVideo(undefined);
    if (!isLoading) {
      setLoading(true);
    }
    if (rtcClientRef.current) {
      await rtcClientRef.current.leave();
    }
    if (friend_Call) {
      await getOtherUserDetail(friend_Call.friend_id);
    }
    const createChannelResponse = await createChannel(friend_Call.friend_id);
    const { tracks, client } = await connectToAgoraRtc(
      createChannelResponse.body.channel,
      createChannelResponse.body.token,
      (themVideo) => setThemVideo(themVideo),
      (myVideo) => setMyVideo(myVideo),
      (themAudio) => setThemAudio(themAudio),
    );
    rtcClientRef.current = client;

  };

  const handleStartCall = async () => {
    setLoading(true);
    connectToUser();
  };

  const handleLeaveCall = async (end) => {
    setCallEndTime(new Date());
    if (localStorage.getItem("activeChatUser") && localStorage.getItem("startTime") != undefined && end != "end") {
      await sendMessage(new Date())
      // await friendCallEnd(new Date(),)
    }
    if (localStorage.getItem("activeChatUser") && end != "left") {
      navigate(ROUTES.MESSAGES, { state: { friend: friend_user } })
    }
    else {
      if (pathname == ROUTES.MESSAGES) {
        navigate(ROUTES.MESSAGES, { state: { friend: friend_user } })
      }
      else {
        navigate(pathname)
      }
    }
    setIsInterestModalOpen(false)
    userJoined.ref = false
    setMuteCall(false);
    await myVideo?.close();
    await themVideo?.close();
    await themAudio?.close();
    setCallStartOpen(false)
    if (end == "end" || end == "cutCall") {
      localStorage.removeItem("otherResponse")
      localStorage.removeItem("activeChatUser")
      localStorage.removeItem("startTime")
      localStorage.removeItem("callOption")
      setReceiveCall(false)
    }
    await client.leave()
    client.removeAllListeners()
    if (channelRef.current) {
      await channelRef.current.leave();
    }
    if (localTracks.length > 0) {
      localTracks.forEach((track) => {
        track.close();
      });
      setLocalTracks([]);
    }
    if (rtcClientRef.current) {
      rtcClientRef.current.leave();
    }
    setRoom('');
    setMessages([]);
    setShowChat(false);
    setLoading(false);
    setVideoCallStart(false);
    setThemVideo(null);
    setMyVideo(null);
  };

  const handleMuteUnmute = () => {
    localTracks[0].setMuted(!muteCall)
    setMuteCall(!muteCall);
  }

  const handleMuteVideo = () => {
    if (localTracks && localTracks[1]) {
      if (muteVideoCall) {
        localTracks[1].setEnabled(true);
      } else {
        localTracks[1].setEnabled(false);
      }
      setMuteVideoCall(!muteVideoCall);
    } else {
      console.error("Video track is not available.");
    }
  };


  function formatCallDuration(duration) {
    const totalSeconds = Math.floor(duration / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  const sendMessage = async (callEndTime) => {
    const callStartTimeString = localStorage.getItem("startTime");
    const callStartTime = new Date(callStartTimeString);
    if (isNaN(callStartTime.getTime())) {
      console.error("Invalid callStartTime:", callStartTimeString);
      return;
    }

    if (callEndTime) {
      const callDuration = callEndTime.getTime() - callStartTime.getTime();
      const data = {
        chat_id: friend_Call?.chatroomId,
        message: callOption == 1 ? "Voice Call" : "Video Call",
        type: "TEXT",
        duration: formatCallDuration(callDuration)
      };
      try {
        const response = await handleRequest("POST", SEND_MESSAGE, data);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Invalid call end time.");
    }
  };

  const friendCallEnd = async (callEndTime) => {
    const callStartTimeString = localStorage.getItem("startTime");
    const callStartTime = new Date(callStartTimeString);
    if (isNaN(callStartTime.getTime())) {
      console.error("Invalid callStartTime:", callStartTimeString);
      return;
    }
    const callDuration = callEndTime.getTime() - callStartTime.getTime();
    try {
      const response = await handleRequest(
        "GET",
        `${FRIEND_CALL_END}?receiverId=${friend_Call?.friend_id}&duration=${formatCallDuration(callDuration)}&calling_type=${callOption == 1 ? 0 : 1}`
      );
      setRoom(response.body.channel);
      setChannelForChat(response.body.channel);
      return response;
    } catch (error) {
      console.log(error)
    }
  };

  const handleLeaveCallWrapper = async (reason) => {
    if (callEnded.current) return;
    callEnded.current = true;
    await handleLeaveCall(reason);
  };

  useEffect(() => {
    setEndedChatModalFriend(false);
    if (!receiveCall && friend_Call) {
      handleStartCall();
      setCallStartOpen(true);
      localStorage.setItem("friendCalling", 1);
    } else {
      setLoading(true);
      setCallStartOpen(true);
    }

    client.on("user-left", async (user, reason) => {
      userJoined.current = false;
      await setCallEndTime(new Date());
      await handleLeaveCallWrapper("left");
      await setEndedChatModalFriend(true);
      if (pathname == ROUTES.MESSAGES) {
        navigate(ROUTES.MESSAGES, { state: { friend: friend_user } })
      }
      else {
        navigate(pathname)
      }
    });

    client.on("user-unpublished", (themUser, mediaType) => {
      console.log(themUser, "themUser")
      if (themUser?._video_muted_ == true || mediaType == "video") {
        setThemVideo(null);
      }
      if (themUser?._audio_muted_ == true || mediaType == "audio") {
        setThemAudio(null);
      }
      // if (themUser?._video_added_ === false) {
      //   console.log("bbbbbbb");
      //   navigate(pathname);
      //   handleLeaveCallWrapper();
      // }
    });

    client.on("crypt-error", async () => {
      await handleLeaveCallWrapper();
    });

    client.on("live-streaming-error", async () => {
      await handleLeaveCallWrapper();
    });

    window.addEventListener("beforeunload", async () => {
      await handleLeaveCallWrapper();
    });

    return async () => {
      client.off("user-left");
      client.off("user-unpublished");
      client.off("crypt-error");
      client.off("live-streaming-error");
      if (client._joinAndNotLeaveYet && (localStorage.getItem("startTime") == undefined || localStorage.getItem("startTime") == null)) {
        getCancelCall();
      } else {
        await handleLeaveCallWrapper()
      }
    };
  }, []);

  const handleSendButtonClick = () => {
    if (toastId) {
      toast.dismiss(toastId);
    }
  };
  console.log(themVideo, "wertyuio")

  const handleAcceptCall = async () => {
    setCallStart(true)
    setRoom(other_response.channel);
    setChannelForChat(other_response.channel);
    if (other_response.user_id) {
      await getOtherUserDetail(other_response?.user_id);
    }
    const result = await connectToAgoraRtc(
      other_response?.channel,
      other_response?.token,
      (themVideo) => setThemVideo(themVideo),
      (myVideo) => setMyVideo(myVideo),
      (themAudio) => setThemAudio(themAudio),
    );
    if (result) {
      const { tracks, client } = result;
      rtcClientRef.current = client;
    } else {
      console.error("connectToAgoraRtc did not return a valid result");
    }
  };

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(async () => {
        await handleLeaveCall()
        if (!receiveCall) {
          toast(`${friend_Call?.username} did not answer the call`, {
            style: { color: 'white' }
          });
        }
      }, 60000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isLoading]);

  useEffect(() => {
    let timer;
    const handleMouseMove = () => {
      setControlsVisible(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
      }, 3000);
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div>
      <div className="video-start-main position-relative video-start-head">
        {(callStart) && (
          <div className="loader-style spinner-background-home">
            <Spinner />
          </div>
        )}
        {isChatting && !isLoading ? (
          <>

            <div className="next-call-position friend-call-icons video-control-btns d-flex gap-3">
              <img style={{ cursor: 'pointer' }} src={muteCall ? IMAGES.MUTE_AUDIO : IMAGES.UNMUTE_AUDIO} onClick={handleMuteUnmute} />
              <img style={{ cursor: 'pointer' }} src={IMAGES.RED_CUT_cALL} onClick={() => setConfirmation(true)} />
              <img style={{ cursor: 'pointer' }} src={!muteVideoCall ? IMAGES.VIDEO_ON : IMAGES.VIDEO_OFF} onClick={handleMuteVideo} /></div>

            <div className="chat-window" onClick={handleSendButtonClick}>
              {controlsVisible && <div onClick={handleShowChatWindow} className={showChat ? "message-icon-links toggle-chat-icon " : "message-icon-links chat-call-icon "}>
                <img style={{ zIndex: "222" }} src={IMAGES.CHAT_ICON} alt="message-icon" className="video-call-icons " />
              </div>}
              <div>
                <div className="video-stream ourvideo-call our-new-call">
                  {!muteVideoCall ? (
                    <VideoPlayer
                      // id="myVideoElementId"
                      videoTrack={myVideo}
                      className="my-video"
                    />)
                    :
                    <div className="my-video-pause">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={IMAGEURL + userDetail?.avatar} alt="" className="myVideo-pause-img mt-4 mt-sm-5" />
                      </div>
                    </div>
                  }
                </div>
                <div className="video-stream therevideo-call">
                  {themVideo == null ?
                    <div className="video-pause">
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <img src={receiveCall ? IMAGEURL + other_response?.avatar : IMAGEURL + friend_Call?.avatar} alt="" className="video-pause-img" />
                      </div>
                    </div> : (
                      <VideoPlayer
                        // id="themVideoElementId"
                        style={{ width: "100%", height: "100%" }}
                        videoTrack={themVideo}
                      />
                    )}
                </div>
              </div>
            </div>
          </>
        ) : (

          <div className="web-cam">
            {
              (isLoading) &&
              <> {(
                <div className="row">
                  <div className="col-12 p-0">
                    <div className="loder-background">
                      <div className="position-relative">
                        <img
                          src={IMAGES.LODER_LOGO}
                          alt="logo-image"
                          className="loder-logo"
                        />
                        <Lottie
                          animationData={loader_gif}
                          loop={true}
                          className="loder-svg"
                        />
                        {
                          receiveCall ?
                            <img
                              src={
                                other_response?.avatar
                                  ? IMAGEURL + other_response?.avatar
                                  : IMAGES.USER_ICON
                              }
                              alt="user-image"
                              className={
                                interest_matching === 1
                                  ? "loder-user-icon"
                                  : "loder-user-img"
                              }
                            /> : <img
                              src={
                                friend_Call?.avatar
                                  ? IMAGEURL + friend_Call.avatar
                                  : IMAGES.USER_ICON
                              }
                              alt="user-image"
                              className={
                                interest_matching === 1
                                  ? "loder-user-icon"
                                  : "loder-user-img"
                              }
                            />
                        }
                        {
                          receiveCall ?
                            <div className="text-center">
                              <p className="loder-user-text ps-5">
                                {other_response?.username}
                              </p>
                            </div> : <div className="text-center">
                              <p className="loder-user-text ps-5">
                                {friend_Call?.username}
                              </p>
                            </div>
                        }
                        <div className={`Connecting-status connect-status d-flex justify-content-start align-items-center pb-5 ${receiveCall && "video-receive-btn receive-connect-status "}`} style={{ cursor: 'pointer' }}>
                          {
                            receiveCall ?
                              <>
                                <img src={IMAGES.RED_CALL} onClick={getCancelCall} className="recive-call me-5" />
                                <img src={IMAGES.GREEN_CALL} style={{ cursor: 'pointer' }} onClick={handleAcceptCall} className="recive-call" /> </> :
                              <>
                                <p className="connect-dots">Calling</p>
                                <div className="spinner">
                                  <div className="bounce1"></div>
                                  <div className="bounce2"></div>
                                  <div className="bounce3"></div>
                                </div>
                              </>
                          }


                        </div>
                        <>   {
                          !receiveCall && <button type="button" onClick={() => getCancelCall()} className={receiveCall ? "cancel-btn-home cancle-call-btn" : "cancel-btn-home"}>
                            Cancel
                          </button>
                        }</>
                      </div>
                    </div>
                  </div>
                </div>
              )}</>
            }

          </div>
        )}
      </div>
      {
        confrimation && <ConfirmModal
          setConfirmation={setConfirmation} handleLeaveCall={handleLeaveCallWrapper} check="friendCall" />
      }
    </div>

  );
};

export default VideoCall;
