import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Layout from "./modules/layout/layout"
import NoData from './modules/noData';
import { ROUTES } from './routes/routes';
import LandingPage from './modules/auth/landingPage';
import AuthLayout from "./modules/auth/auth-layout/authLayout"
import SignInLayout from './modules/auth/signin';
import SignUpLayout from './modules/auth/signup';
import TryFree from './modules/auth/try-free/tryFree';
import Friends from './modules/myAsocial/friends/friends';
import Messages from './modules/myAsocial/messages/messages';
import VideoChatHistory from './modules/myAsocial/videoChatHistory/videoChatHistory'
import Home from './modules/home/home';
import './assets/css/landing.css';
import './assets/css/global.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import { SidebarProvider } from './context/sidebarContext';
import FriendCall from './modules/FriendCall/friendCall';

const ProtectedRoute = ({ element }) => {
  const location = useLocation();
  const isProgrammaticNavigation = location.state?.programmatic || false;
  return isProgrammaticNavigation ? element : <Navigate to={ROUTES.HOME} />;
};

 


const UserRoutes = () => {
  return (
    <SidebarProvider>
      <Layout>
        <Routes>
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.FRIENDS} element={<Friends />} />
          <Route path={ROUTES.MESSAGES} element={<Messages />} />
          <Route path={ROUTES.VIDEO_CHAT_HISTORY} element={<VideoChatHistory />} />
          <Route path={ROUTES.VIDEO_CALL} element={<ProtectedRoute element={<FriendCall />} />} />
          <Route path='*' element={<NoData />} />
        </Routes>
      </Layout>
    </SidebarProvider>
  );
};   

const PublicRoutes = () => {
  return (
    <AuthLayout>
      <Routes>
        <Route path={ROUTES.SIGNIN} element={<SignInLayout />} />
        <Route path={ROUTES.SIGNIN} element={<SignUpLayout/>} />
        <Route path={ROUTES.SIGNUP} element={<Navigate to={ROUTES.SIGNIN}/>} />
        <Route path={ROUTES.TRYFREE} element={<TryFree />} />
        <Route path='*' element={<NoData />} />
      </Routes>
    </AuthLayout>
  )
}

function App() {
  const location = useLocation();
  let token = localStorage.getItem("token");
  if (location.pathname === ROUTES.SIGNIN || location.pathname === ROUTES.SIGNUP || location.pathname === ROUTES.TRYFREE) {
    if (token !== null) {
      return <Navigate to={ROUTES.HOME} />
    }
  }

  return (
    <>
      {
        token !== null ?
          <UserRoutes /> :
          <PublicRoutes />
      }
    </>
  )
  
}

export default App
