import React, { useEffect, useRef, useState } from "react";
import VideoRoom from "./components/VideoRoom"
import { IMAGES } from "../../components/images";
import EndedChatModal from "../modals/endedChatModal";
import { useSidebarContext } from "../../context/sidebarContext";
import { Link } from "react-router-dom";
import RTMChat from "./components/RTMChat";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client';
import { IMAGEURL, SOCKETURL } from "../../config";
import ConfirmModal from "../modals/areYouSure";
const socket = io(SOCKETURL)

const Home = () => {
  const [toastId, setToastId] = useState(null);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const user_id = userData?.userdata?.id ? userData?.userdata?.id : userData?.id
  const accountType = userData?.userdata?.account_type ? userData?.userdata?.account_type : userData?.account_type
  const { isSidebarVisible, setIsSidebarVisible, channelForChat, setChannelForChat, messages, setMessages, userDetail, setIsLodaingMain, endedChatModalFriend } = useSidebarContext();
  const [endedChatModal, setEndedChatModal] = useState(false);
  const [otherUser, setOtherUser] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [isAutoRollOn, setIsAutoRollOn] = useState(false);
  const [responseStatus, setResponseStatus] = useState(0);
  const [videoCallStart, setVideoCallStart] = useState(false)
  const interest_matching = localStorage.getItem("interest_matching")
  const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);
  const [webcamLoading, setWebcamLoading] = useState(true);
  const [interestArray, setInterestArray] = useState([]);
  const [autoCheck, setAutoCheck] = useState(false)
  const [confrimation, setConfirmation] = useState(false)

  const handleSwitchOnOff = () => {
    localStorage.setItem("auto", 0)
    if (isAutoRollOn === false) {
      setIsAutoRollOn(true)
    } else {
      setIsAutoRollOn(false)
    }
    if (videoCallStart) {
      setConfirmation(true)
    }
  }

  const handleShowChatWindow = () => {
    // if (!showChat) {
    //   setIsSidebarVisible(false);
    // }
    setShowChat(!showChat);
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const user_id = userData?.userdata?.id ? userData?.userdata?.id : userData?.id
    const handleReceivedMessage = (data) => {
      const truncateMessage = (message, charLimit) => {
        if (message.length > charLimit) {
          return message.slice(0, charLimit) + '...';
        }
        return message;
      };

      const truncatedMessage = truncateMessage(data.message, 50);

      if (showChat === false) {
        const id = toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {
              data.userImage ? (
                <img
                  src={`${IMAGEURL}${data.userImage}`}
                  style={{
                    marginRight: '10px',
                    maxWidth: '50px',
                    height: '50px',
                    width: '50px',
                    borderRadius: '100%'
                  }}
                />
              ) : (
                <img
                  src={IMAGES.USER_ICON}
                  style={{
                    marginRight: '10px',
                    maxWidth: '50px',
                    height: '50px',
                    width: '50px',
                    borderRadius: '100%'
                  }}
                />
              )
            }
            <span className="text-white fw-500 f14-size">{truncatedMessage}</span>
          </div>,
          {
            autoClose: 300000
          }

        );
        setToastId(id)
      }

      setMessages(prevMessages => [...prevMessages, {
        text: data.message,
        sender: data.sender_id == user_id ? "me" : 'other',
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
      }]);
    };

    socket.on(`videoCallingChat${channelForChat}`, handleReceivedMessage);
    socket.on(`useristyping${user_id}`, (socketResponse) => {
      console.log(socketResponse, "socket")
      setResponseStatus(socketResponse.status);
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    return () => {
      socket.off(`videoCallingChat${channelForChat}`, handleReceivedMessage);
      socket.off(`useristyping${user_id}`);
    };
  }, [channelForChat, showChat]);

  const fetchFlagUrl = (countryCode) => {
    console.log(countryCode, "countryCodecountryCode")
    if (!countryCode) {
      console.error('No country code provided');
      return '';
    }
    try {
      return `https://flagpedia.net/data/flags/h80/${countryCode.toLowerCase()}.png`;
    } catch (error) {
      console.error('Error constructing flag URL:', error);
      return '';
    }
  };

  return (
    <>
      <div className={`video-chat-wrapper ${showChat && "custom-video-chat"}`}>
        <div className={`video-picture position-relative ${showChat && "message-video-call"}`}>
          {
            videoCallStart && <div className={isSidebarVisible ? "video-profile d-flex justify-content-start gap-2 " : "video-profile d-flex justify-content-start gap-2 side-video-profile"}>
              <div className="video-profile-head">
                <img src={otherUser.avatar ? IMAGEURL + otherUser.avatar : IMAGES.USER_ICON} alt="user-profile" className="user-profile-img" />
              </div>
              <div>
                <p className="fw-600 text-white f16-size mb-0" style={{ lineHeight: '25px' }}>{otherUser.username}</p>
                {
                  otherUser.account_type == 0 && otherUser.gender ?
                    <img src={otherUser.gender == 1 ? IMAGES.MALE_SELECTED : otherUser.gender == 2 ? IMAGES.FEMALE_SELECTED : IMAGES.NOBINARY_SELECTED} className="flag-icon" />
                    :
                    <>
                      <div className="d-flex" style={{ gap: '10px' }}>
                        <div>
                          <img src={otherUser.flag ? fetchFlagUrl(otherUser.flag) : IMAGES.FLAG} className="flag-icon" /></div>
                        <div>
                          <img src={otherUser.gender == 1 ? IMAGES.MALE_SELECTED : otherUser.gender == 2 ? IMAGES.FEMALE_SELECTED : IMAGES.NOBINARY_SELECTED} className="flag-icon" /></div>
                      </div>
                    </>
                }
              </div>
            </div>
          }
          {
            videoCallStart && interest_matching == 1 && (<div className="pt-4 pb-5 matched-results" style={{ cursor: 'pointer' }}>
              <div style={{ borderRadius: 30, background: "transparent" }}
                className="owninterest d-flex align-items-center gap-2 px-3 p-2"
              >
                <div className="matched-interests p-2">
                  <div className="d-flex flex-wrap align-items-center">
                    {
                      interestArray.slice(0, 3).map((interest, index) => (
                        <button key={index} className="custom-matched-interest-list">{interest}</button>
                      ))
                    }
                    {interestArray.length > 3 && (
                      // <a className="text-white f18-size fw-500 ms-1"  ></a >
                      <button onClick={() => setIsInterestModalOpen(true)} className="custom-matched-interest-list">More...</button>
                    )}
                  </div>
                </div>
              </div>
            </div>)
          }


          {/* {  !isSidebarVisible && <div className="hamburger-icon">
            <Link onClick={handleSidebarOpen} style={{cursor:"pointer"}}  className="hamburger"><img onClick={handleSidebarOpen} style={{cursor:"pointer"}} src={IMAGES.HAMBURGER} alt="HAMBURGER-ICON" /></Link>
          </div>} */}
          {/* <div className="img-chat"></div> */}
          <div className="video-button-wrapper">
            <VideoRoom setOtherUser={setOtherUser} otherUser={otherUser} accountType={accountType} handleShowChatWindow={handleShowChatWindow} setShowChat={setShowChat} showChat={showChat} isAutoRollOn={isAutoRollOn} setVideoCallStart={setVideoCallStart} user_id={userDetail?.id} setEndedChatModal={setEndedChatModal} setIsInterestModalOpen={setIsInterestModalOpen} isInterestModalOpen={isInterestModalOpen} webcamLoading={webcamLoading} setWebcamLoading={setWebcamLoading} interestArray={interestArray} setInterestArray={setInterestArray} toastId={toastId} autoCheck={autoCheck} videoCallStart={videoCallStart} confrimation={confrimation} setConfirmation={setConfirmation} />
          </div>
        </div>
        {(endedChatModal) && <EndedChatModal setIsAutoRollOn={setIsAutoRollOn} isAutoRollOn={isAutoRollOn} otherUser={otherUser} setEndedChatModal={setEndedChatModal} toast={toast} setAutoCheck={setAutoCheck} />}

        {
          showChat && <RTMChat otherUser={otherUser} setShowChat={setShowChat} responseStatus={responseStatus} />
        }

      </div>
      {
        !showChat &&
        <div className="toggle-chat-wrapper">
          <p className="auto-text fw-600 mb-0 text-white">Autoroll</p>
          <div className="on-off-toggle" style={{ zIndex: "2222" }}>
            <input
              className="on-off-toggle__input"
              type="checkbox"
              id="bopis"
              checked={isAutoRollOn}
              onChange={handleSwitchOnOff}
            />
            <label for="bopis" className="on-off-toggle__slider"></label>
          </div>
        </div>
      }
    </>
  )
};

export default Home;

