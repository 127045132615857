import logo from "../assets/images/logo.svg"
import splash from "../assets/images//white-splash.svg"
import banner_img from "../assets/images/banner_img.png"
import offwhite from "../assets/images/offwhite-splash.svg"
import creative from "../assets/images/creative.png"
import try_image from "../assets/images/try_image.png"
import auto_roll from "../assets/images/auto_roll.png"
import customimg from "../assets/images/customimg.png"
import footer_logo from "../assets/images/footer-logo.svg"
import facebook from "../assets/images/facebook.svg"
import instagram from "../assets/images/instagram.svg"
import linkedin from "../assets/images/linkedin.svg"
import twitter from "../assets/images/twitter.svg"
import logo_gradient from "../assets/images/logo-gradient.svg"
import phone from "../assets/images/phone.svg"
import fblogin from "../assets/images/fblogin.svg"
import google from "../assets/images/google.svg"
import verify from "../assets/images/verify.svg"
import username from "../assets/images/username.svg"
import unlock from "../assets/images/unlock.svg"
import calender from "../assets/images/calender.svg"
import female from "../assets/images/female.svg"
import male from "../assets/images/male.svg"
import nonbinary from "../assets/images/Nonbinary.svg"
import camera_icon from "../assets/images/camera-icon.svg"
import avatar_female from "../assets/images/avatar-female.png"
import avatar_male from "../assets/images/avatar-male.png"
import avatar4 from "../assets/images/avatar4.png"
import avatar5 from "../assets/images/avatar5.png"
import avatar6 from "../assets/images/avatar6.png"
import avatar7 from "../assets/images/avatar7.png"
import avatar8 from "../assets/images/avatar8.png"
import avatar9 from "../assets/images/avatar9.png"
import account_created from "../assets/images/account_created.svg"
import close_btn from "../assets/images/close-btn.svg"
import gallery from "../assets/images/gallery.png"
import dash_logo from "../assets/images/dash_logo.svg"
import dashboard from "../assets/images/dashboard.svg"
import setting from "../assets/images/setting.svg"
import user from "../assets/images/user.svg"
import videoicon from "../assets/images/videoicon.svg"
import myasocialicon from "../assets/images/myasocialicon.svg"
import logout from "../assets/images/logout.svg"
import whitemyasocial from "../assets/images/whitemyasocial.svg"
import whitevideo from "../assets/images/whitevideo.svg"
import dots_circle from "../assets/images/dots-circle.svg"
import female_selected from "../assets/images/female_selected.svg"
import female_unselected from "../assets/images/female_unselected.svg"
import male_selected from "../assets/images/male_selected.svg"
import male_unselected from "../assets/images/male_unselected.svg"
import Nonbinary_selected from "../assets/images/Nonbinary_selected.svg"
import Nonbinary_unselected from "../assets/images/Nonbinary_unselected.svg"
import white_cross from "../assets/images/white_cross.svg"
import chatsearch from "../assets/images/chatsearch.svg"
import vertical_dot from "../assets/images/vertical-dot.svg"
import avatar_10 from "../assets/images/avatar_8.svg"
import avatar_11 from "../assets/images/avatar_9.svg"
import avatar_12 from "../assets/images/avatar_10.svg"
import avatar_13 from "../assets/images/avatar_13.svg"
import avatar_14 from "../assets/images/avatar_14.svg"
import avatar_15 from "../assets/images/avatar_15.svg"
import camera_modal from "../assets/images/camera_modal.svg"
import videoCallImage from "../assets/images/videoCallImage.png"
import start_video from "../assets/images/start_video.svg"
import arrow_pink from "../assets/images/arrow-pink.svg"
import hamburger_icon from "../assets/images/hamburger-icon.svg"
import caution_icon from "../assets/images/caution-icon.svg"
import toggle_close from "../assets/images/toggle-close.svg"
import rtm_message from "../assets/images/rtm-message.svg"
import rtmChatCloseIcon from "../assets/images/cross-icon.png"
import muteCall from "../assets/images/muteCall.svg"
import unmuteCall from "../assets/images/unmuteCall.svg"
import noData from "../assets/images/asocial_no_data.png"
import edit_icon from "../assets/images/edit_icon.svg"
import user_icon from "../assets/images/user-img.svg"
import loder_logo from "../assets/images/loder-logo.svg"
import white_icon from "../assets/images/white_cross.png"
import backArrow from "../assets/images/back-arrow.png"
import unlockFeature from "../assets/images/unlock_features.svg"
import newUser from "../assets/images/new_user.svg"
import asocialWhite from "../assets/images/Asocial_white.svg"
import addFriend from "../assets/images/add-friend.png"
import sentRequest from "../assets/images/request-sent.png"
import pending from "../assets/images/pending.svg"
import approved from "../assets/images/approved.svg"
import settingtheme from "../assets/images/settingtheme.svg"
import usertheme from "../assets/images/usertheme.svg"
import arrowbottom from "../assets/images/downArroaChat.png"
import flagImg from "../assets/images/flag-image.png"
import landingImgOne from "../assets/images/banner_img-PTu7oDW9.png"
import landingImgTwo from "../assets/images/creative-iof9feZU.png"
import landingImgThree from "../assets/images/try_image-ZPcUW2n4.png"
import landingImgFour from "../assets/images/auto_roll-DawUGyN_.png"
import landingImgFive from "../assets/images/customimg-ybe5YcXY.png"
import doubleArrow from "../assets/images/double-arrow.svg"
import createLogo from "../assets/images/create-logo.png"
import cameraImg from "../assets/images/camera.svg"
import backgroundImg from "../assets/images/banner_asocial.jpg"
import audio from "../assets/audio/4WT37WU-ui-sci-fi-sci-fi-bleep-03.mp3"
import anouncement from "../assets/images/anouncement.svg"
import avtar_one from "../assets/images/avtar_1.png"
import avtar_two from "../assets/images/avtar_2.png"
import avtar_three from "../assets/images/avtar_3.png"
import avtar_four from "../assets/images/avtar_4.png"
import avtar_five from "../assets/images/avtar_5.png"
import avtar_six from "../assets/images/avtar_6.png"
import avtar_seven from "../assets/images/avtar_7.png"
import short_avatar from "../assets/images/short_avatar.png"
import video_call from "../assets/images/vc.svg"
import greenCall from "../assets/images/green_call.svg"
import redCall from "../assets/images/red_call.svg"
import red_cut_call from "../assets/images/red_cut_video.svg"
import mute_audio from "../assets/images/audio_off.svg"
import unmute_audio from "../assets/images/unmute.svg"
import video_off from "../assets/images/video_off.svg"
import video_on from "../assets/images/video_on.svg"
import male_white from "../assets/images/male_white.svg"
import female_white from "../assets/images/female_white.svg"
import nonbinary_white from "../assets/images/nonbinary_white.svg"
import audio_icon from "../assets/images/audio_icon.svg"
import video_icon from "../assets/images/video_icon.svg"
import chat_icon from "../assets/images/chat_icon.svg"
import flag from "../assets/images/flag.svg"


export const IMAGES = {
    LOGO: logo,
    SPLASH: splash,
    BANNER: banner_img,
    OFFWHITE: offwhite,
    CREATIVE: creative,
    TRYIMAGE: try_image,
    AUTOROLL: auto_roll,
    CUSTOMIMG: customimg,
    FOOTERLOGO: footer_logo,
    FACEBOOK: facebook,
    INSTAGRAM: instagram,
    LINKEDIN: linkedin,
    TWITTER: twitter,
    LOGOGRADIENT: logo_gradient,
    PHONE: phone,
    FBLOGIN: fblogin,
    GOOGLELOGIN: google,
    VERIFY: verify,
    USERNAME: username,
    UNLOCK: unlock,
    CALENDER: calender,
    MALE: male,
    FEMALE: female,
    NOBINARY: nonbinary,
    CAMERAICON: camera_icon,
    AVATARFEMALE: avatar_female,
    AVATARMALE: avatar_male,
    AVATAR4: avatar4,
    AVATAR5: avatar5,
    AVATAR6: avatar6,
    AVATAR7: avatar7,
    AVATAR8: avatar8,
    AVATAR9: avatar9,
    ACCOUNTCREATED: account_created,
    CLOSSBUTTON: close_btn,
    GALLERY: gallery,
    DASHLOGO: dash_logo,
    DASHICON: dashboard,
    SETTINGICON: setting,
    USERICON: user,
    VIDEOICON: videoicon,
    MYASOCIALICON: myasocialicon,
    LOGOUTICON: logout,
    WHITEMYASOCIALICON: whitemyasocial,
    WHITEVIDEOICON: whitevideo,
    DOTSCIRCLE: dots_circle,
    FEMALE_SELECTED: female_selected,
    FEMALE_UNSELECTED: female_unselected,
    MALE_SELECTED: male_selected,
    MALE_UNSELECTED: male_unselected,
    NOBINARY_SELECTED: Nonbinary_selected,
    NOBINARY_UNSELECTED: Nonbinary_unselected,
    WHITECROSS: white_cross,
    INPUTSEARCH: chatsearch,
    VERTICALDOT: vertical_dot,
    AVATAR_10: avatar_10,
    AVATAR_11: avatar_11,
    AVATAR_12: avatar_12,
    AVATAR_13: avatar_13,
    AVATAR_14: avatar_14,
    AVATAR_15: avatar_15,
    CAMERAMODAL: camera_modal,
    BACKUP_IMAGE: videoCallImage,
    STARTVIDEO: start_video,
    ARROWPINK: arrow_pink,
    HAMBURGER: hamburger_icon,
    CAUTION: caution_icon,
    TOGGLE_CLOSE: toggle_close,
    RTM_MESSAGE: rtm_message,
    RTMCHATCLOSEICON: rtmChatCloseIcon,
    MUTECALL: muteCall,
    UNMUTECALL: unmuteCall,
    NO_DATA: noData,
    EDIT_ICON: edit_icon,
    USER_ICON: user_icon,
    LODER_LOGO: loder_logo,
    WHITE_ICON: white_icon,
    BACK_ARROW: backArrow,
    UNLOCK_FEATURE: unlockFeature,
    NEW_USER: newUser,
    ASOCIAL_WHITE: asocialWhite,
    ADD_FRIEND: addFriend,
    SENT_REQUEST: sentRequest,
    PENDING_REQUEST: pending,
    APPROVED_REQUEST: approved,
    USERTHEME: usertheme,
    SETTINGTHEME: settingtheme,
    ARROWBOTTOM: arrowbottom,
    FLAG_IMG: flagImg,
    LANDING_IMG_ONE: landingImgOne,
    LANDING_IMG_TWO: landingImgTwo,
    LANDING_IMG_THREE: landingImgThree,
    LANDING_IMG_FOUR: landingImgFour,
    LANDING_IMG_FIVE: landingImgFive,
    DOUBLE_ARROW: doubleArrow,
    CREATE_LOGO: createLogo,
    CAMERAIMG: cameraImg,
    BACK_IMG: backgroundImg,
    AUDIO: audio,
    ANNOUNCEMENT: anouncement,
    AVTAR_ONE: avtar_one,
    AVTAR_TWO: avtar_two,
    AVTAR_THREE: avtar_three,
    AVTAR_FOUR: avtar_four,
    AVTAR_FIVE: avtar_five,
    AVTAR_SIX: avtar_six,
    AVTAR_SEVEN: avtar_seven,
    SHORT_AVATAR: short_avatar,
    VIDEO_CALL: video_call,
    GREEN_CALL: greenCall,
    RED_CALL: redCall,
    MUTE_AUDIO: mute_audio,
    UNMUTE_AUDIO: unmute_audio,
    VIDEO_OFF: video_off,
    VIDEO_ON: video_on,
    RED_CUT_cALL: red_cut_call,
    MALE_WHITE: male_white,
    FEMALE_WHITE: female_white,
    NONOBINARY_WHITE: nonbinary_white,
    VIDEO_ICON: video_icon,
    AUDIO_ICON: audio_icon,
    CHAT_ICON:chat_icon,
    FLAG:flag
}